<template>
    <div :class="['ew-field-group', 'ew-field-group-horizontal', {'ew-field-group-vertical': orientation === 'v'}]">
        <label v-if="showLabel" :class="[
        'ew-field-label', 'text-align-right', 'flex1', ' ew-label-weight',
        {'text-align-left': orientation === 'v'}]" >{{ $t(label) }}</label>
        <div class="flex1 position-relative">
          <div v-if="isEditing" >
              <Input id="inputField" readonly :label="''" v-if="localValue" :value="localValue.name" :isDisabled="isDisabled" @click="click()"/>
              <Button id="hierarchyBtn" v-else :label="$t('click_here_to_select')" color="secondary" type="text" @click="click()"/>
          </div>
          <div v-else >
              <label  :class="[ 'ew-field-label', 'text-align-right', 'flex1',{'text-align-left': orientation === 'v'}]">{{ localValue.name }}</label>
          </div>
        </div>
        <Modal v-if="isModalVisible" :headingText="label" :disableConfirmBtn="hasError" @close="close()" @click="onOk()">
          <div slot="body">
            <div name = "body" v-for="f in filteredFilters" :key="f.Order" class="form-group">
                <Select
                :placeholder="f.Placeholder !== null ? f.Placeholder : ''"
                :label="f.Label"
                :is-multi-select="false"
                :allOptions="filterOptions(f.Options)"
                :name="'Name'"
                :value="getSelectedVAlueTransformedForOrder(f.Order)? getSelectedVAlueTransformedForOrder(f.Order): ''"
                @input="onSelect($event, getSelectedVAlueTransformedForOrder(f.Order))"
                />
                <br>
            </div>
            <div v-if="loadingOptions" class="loading">Loading...</div>
            <div v-if="hasError" id="hierarchyError" class="error-color error-message">{{ $t(errorMessage) }}</div>
          </div>
         </Modal>
    </div>
</template>
<script>
import { mapState, mapActions } from 'vuex'
import { Map } from 'immutable'
import { createMapOf, IsNullOrUndefinedOrEmptyString, isNotNullNorEmpty } from '../../../utils/utils'
import Button from './Button.vue'
import Select from './Select.vue'
import Input from './Input.vue'
import Modal from './Modal.vue'
import { constants } from '../../../constants/constants'
import { ComponentMixin } from '@/app/shared/mixins/ComponentMixin'
export default {
  name: 'HierarchySelectionField',
  components: { Button, Select, Input, Modal },
  mixins: [ComponentMixin],
  props: {
    hierarchyConfigs: {
      type: Array,
      default: () => []
    },
    labelKey: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    isEditing: {
      type: Boolean,
      default: true
    },
    showLabel: {
      type: Boolean,
      default: true
    },
    value: {
      default: null
    },
    orientation: {
      type: String,
      default: 'h'
    },
    errorMessage: {
      type: String,
      default: 'all_fields_are_required'
    }
  },
  mounted () {
    this.localValue = this.value
    this.getSelectedOptions()
    // Apply validation to update hasFormError value in case if it is prefilled
    this.applyValidation()
  },
  computed: {
    ...mapState('HierarchySelectionField', ['hierarchyConfigsResponse']),
    filteredFilters () {
      return this.fieldConfigMap
        .valueSeq()
        .sortBy(f => f.Order)
        .filter(f => f.Options?.length > 0
        ).toArray()
    }
  },
  methods: {
    ...mapActions('HierarchySelectionField', ['getHierarchyConfigs']),
    filterOptions (arr) {
      return arr.filter(ele => !!ele).map((ele, id) => ({ Key: ele.id, Value: ele.name + ' ' + ele.id + ' ' + ele.code }))
    },
    click () {
      this.isModalVisible = true
      this.hasError = true
      this.applyValidation()
    },
    close () {
      this.isModalVisible = false
    },
    onOk (event) {
      if (!this.finalValueSelected && IsNullOrUndefinedOrEmptyString(this.localValue)) {
        return
      }
      this.isModalVisible = false
      var previousVal = this.localValue
      var selected = this.selectedValues.filter(s => s != null)
      var key = Array.from(selected.keys()).reduce((a, b) => a > b ? a : b)
      var val = selected.get(key)

      this.localValue = val
      if (isNotNullNorEmpty(val)) {
        this.$emit('input', val)
        this.$emit('change', val)
      } else {
        this.localValue = previousVal
      }
      this.hasError = false
      this.finalSelectedValues = Array.from(this.selectedValues.values())
    },
    async getSelectedOptions () {
      if (!IsNullOrUndefinedOrEmptyString(this.localValue)) {
        const params = { id: this.localValue.id }
        await this.getHierarchyConfigs({ url: '/v1/hierarchy/parents', params: params })
        const data = this.hierarchyConfigsResponse
        this.selectedValues = createMapOf('order', data.data)
        this.finalSelectedValues = Array.from(this.selectedValues.values())
      }
      this.renderSelectors()
    },
    renderSelectors () {
      this.fieldConfigMap = createMapOf('Order', this.hierarchyConfigs)
      try {
        this.fieldConfigMap.sortBy(f => f.Level).forEach(fc => {
          if (fc.Order === 1 || !IsNullOrUndefinedOrEmptyString(this.selectedValues.get(fc.Order))) {
            var parent = !IsNullOrUndefinedOrEmptyString(this.selectedValues.get(fc.Order - 1)) ? this.selectedValues.get(fc.Order - 1).id : 0
            this.fetchData(fc.RemoteUrl, fc.Level, fc.Order, parent, fc.Types)
          }
        })
        const lastSelectedValue = this.finalSelectedValues[this.finalSelectedValues.length - 1]
        if (lastSelectedValue && lastSelectedValue.hasChildren) {
          const nextConfig = this.fieldConfigMap.get(lastSelectedValue.order + 1)
          if (nextConfig) {
            this.fetchData(nextConfig.RemoteUrl, lastSelectedValue.level + 1, lastSelectedValue.order + 1, lastSelectedValue.id, nextConfig.Types)
          }
        }
      } catch (error) {
      }
    },
    async fetchData (remoteUrl, level = 0, order, id = 1, types = null) {
      try {
        this.loadingOptions = true
        const existing = this.fieldConfigMap.get(order)
        if (existing && existing.Level !== 0) {
          remoteUrl += constants.HIERARCHY_LEVEL + level + constants.HIERARCHY_PARENT + id + constants.HIERARCHY_TYPES + types.join(',')
          await this.getHierarchyConfigs({ url: remoteUrl })
          let data = this.hierarchyConfigsResponse.data
          data = createMapOf(constants.NAME, data).valueSeq().sortBy(f => f.name).toArray()

          this.fieldConfigMap = this.fieldConfigMap.set(order, {
            ...existing,
            Options: data
          })
        }
        this.loadingOptions = false
      } catch (error) {
      }
    },
    onSelect (val, orignalValue) {
      if (orignalValue && val.Key === orignalValue.Key) {
        return
      }
      this.hasError = true
      const levelConfigMap = createMapOf('Level', this.fieldConfigMap.valueSeq())

      const idOptionMap = {}
      this.fieldConfigMap.forEach((value, key) => {
        for (const option of value.Options) {
          idOptionMap[option.id] = option
        }
      })
      const selectedObject = idOptionMap[val.Key]

      if (selectedObject) {
        const currentConfig = levelConfigMap.get(selectedObject.level)
        this.selectedValues = this.selectedValues.set(currentConfig.Order, selectedObject)
        const self = this
        this.fieldConfigMap.keySeq().forEach(order => {
          if (order > currentConfig.Order) {
            self.selectedValues = self.selectedValues.set(order, null)
            const existing = this.fieldConfigMap.get(order)
            if (existing.Level !== 0) {
              existing.Options = []
              self.fieldConfigMap = self.fieldConfigMap.set(order, existing)
            }
          }
        })

        const nextFetchObj = this.fieldConfigMap.get(currentConfig.Order + 1)

        let types = null
        if (!IsNullOrUndefinedOrEmptyString(nextFetchObj)) {
          types = nextFetchObj.Types
        }
        let id = val.Key
        if (currentConfig.Level === 0) {
          types = selectedObject.Types
          id = this.selectedValues.get(currentConfig.Order - 1).Id
        }
        if (selectedObject.hasChildren || currentConfig.Level === 0) {
          this.finalValueSelected = false
          this.fetchData(nextFetchObj.RemoteUrl, nextFetchObj.Level, nextFetchObj.Order, id, types)
        } else {
          this.finalValueSelected = true
          this.hasError = false
        }
        this.finalSelectedValues = []
      }
    },
    getSelectedVAlueTransformedForOrder (order) {
      const selectedVal = this.selectedValues.get(order)
      return selectedVal ? { Key: selectedVal.id, Value: selectedVal.name + ' ' + selectedVal.id + ' ' + selectedVal.code } : null
    }
  },
  data: self => ({
    localValue: null,
    selectedValues: Map(),
    fieldConfigMap: Map(),
    loadingOptions: false,
    finalSelectedValues: [],
    finalValueSelected: false,
    hasError: false,
    isModalVisible: false
  }),
  watch: {
    selectedValues () {

    }
  }
}
</script>
<style lang='scss' scoped>
.error-message{
  margin: 0px;
  line-height: 16px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}
.modal-backdrop {
position: fixed;
top: 0;
bottom: 0;
left: 0;
right: 0;
background-color: rgba(0, 0, 0, 0.3);
display: flex;
justify-content: center;
align-items: center;
z-index: 1;
}

.modal {
/* box-shadow: 2px 2px 20px 1px; */
overflow-x: auto;
display: flex;
flex-direction: column;
background: $off-white;
border-radius: 16px;
padding: 32px;
width: 400px;
}

.modal-header,
.modal-footer {
display: flex;
justify-content: space-between;
}

.modal-header {
margin-bottom: 16px;
font-style: normal;
font-weight: 500;
font-size: 24px;
line-height: 36px;
display: flex;
align-items: center;
letter-spacing: 0.005em;
/* Dark/Title */
color: $text;
}

.modal-footer {
margin-top: 20px;
justify-content: flex-end;
}

.modal-body {
font-family: IBM Plex Sans;
font-style: normal;
font-weight: 500;
font-size: 16px;
line-height: 26px;
letter-spacing: 0.0125em;
text-align: start;
/* Body Text */
color: $body;
}

.btn-close {
border: none;
cursor: pointer;
font-weight: bold;
height: 18px;
width: 18px;
background-color: transparent;
background-image: url("../../../../public/VectorCross.svg");
background-repeat: no-repeat;
}

.btn-primary {
background: var(--primary-theme);
border: none;
align-items: center;
padding: 19px 24px;
box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.2);
border-radius: 6px;
font-family: IBM Plex Sans;
font-style: normal;
font-weight: bold;
font-size: 14px;
line-height: 14px;
letter-spacing: 0.05em;
text-transform: uppercase;
/* White */
color: $text;
}

.white-button {
color: $text;
background-color: $off-white;
}

.field-flex{
  display: flex;
  text-align: -webkit-center;
}

.field-align{
  align-self: center;
  flex:auto;
}

.position-absolute {
  position: absolute;
  top: 100%;
}

.error-color {
color: $error;
}

.text-info{
  margin-top: 2px;
  color: #0070D9;
  line-height: 16px;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
}
</style>
